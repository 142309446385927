<template>
  <div class="dns">
    <v-row class="elevation-form pa-3 mb-5">
      <v-col cols="12 mb-5">
        <h3>Cache management</h3>
        <div class="d-flex justify-start">
          <div class="my-auto">Clear all cache files on your store</div>
          <v-btn color="primary" @click="clearCache()" class="ml-5">
            Clear cache
            <v-icon small class="pointer" dark right> far fa-trash-alt </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- <v-row class="elevation-form pa-3 mb-5">
      <v-col sm="12">
        <div>DNS management for <strong>depzai.shop</strong></div>
        <v-row>
          <v-col cols="2">
            <v-btn @click="Add"> + Add Records </v-btn>
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="searchString" solo dense placeholder="Search"></v-text-field>
          </v-col>
        </v-row>
        <div>[name] points to [IPv4 address] and has its traffic proxied through Cloudflare.</div>
        <transition name="fade">
          <div v-if="isShowAddRecords">
            <v-row>
              <v-col class="" cols="2">Type</v-col>
              <v-col class="" cols="3">Name</v-col>
              <v-col class="" cols="3">Target</v-col>
              <v-col class="" cols="2">TTL</v-col>
              <v-col class="" cols="2">Proxy status</v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-select
                  :items="itemsSelect"
                  full-width
                  v-model="inputData.type"
                  item-text="title"
                  item-value="value"
                  dense
                  solo
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field class="mt-0" v-model="inputData.name" placeholder="Use @ for root"></v-text-field>
              </v-col>
              <v-col cols="3"><v-text-field class="mt-0" v-model="inputData.address"></v-text-field></v-col>
              <v-col cols="2">
                <v-select
                  v-if="inputData.proxy"
                  :items="TTLSelect"
                  v-model="inputData.TTL"
                  item-text="title"
                  item-value="value"
                  label="Choose here"
                  dense
                  solo
                ></v-select>
                <div class="my-2" v-else>Auto</div>
              </v-col>
              <v-col cols="2">
                <div class="mt-2" v-if="inputData.proxy"><v-icon @click="offDNS" left>mdi-cloud</v-icon> DNS only</div>
                <div class="mt-2" v-if="!inputData.proxy">
                  <v-icon @click="onDNS" left>mdi-cloud-outline</v-icon> Proxied
                </div>
              </v-col>
            </v-row>
            <div class="d-flex justify-end">
              <v-btn class="mr-4" @click="Cancel">Cancel</v-btn>
              <v-btn @click="Save">Save</v-btn>
            </div>
          </div>
        </transition>
      </v-col>
    </v-row>
    <v-row class="elevation-form mb-5">
      <v-col cols="12">
        <v-row class="bg">
          <v-col cols="2">Type</v-col>
          <v-col cols="2">Name</v-col>
          <v-col cols="2">Target</v-col>
          <v-col cols="2">TTL</v-col>
          <v-col cols="2">Proxy status</v-col>
        </v-row>
        <div v-for="(item, ind) in listData" :key="ind">
          <v-row>
            <v-col class="my-auto" cols="2">{{ item.type }}</v-col>
            <v-col class="my-auto" cols="2">{{ item.name }}</v-col>
            <v-col class="my-auto" cols="2">{{ item.address }}</v-col>
            <v-col v-if="item.proxy" class="my-auto" cols="2">{{ item.TTL }}</v-col>
            <v-col v-if="!item.proxy" class="my-auto" cols="2"> Auto </v-col>
            <v-col v-if="item.proxy" class="my-auto" cols="2"><v-icon left>mdi-cloud</v-icon> DNS only</v-col>
            <v-col v-if="!item.proxy" class="my-auto" cols="2"><v-icon left>mdi-cloud-outline</v-icon> Proxied</v-col>
            <v-col class="text-end" cols="2"
              ><div class="pointer" @click="Edit(ind)">Edit <v-icon right>mdi-play</v-icon></div></v-col
            >
          </v-row>
          <v-divider></v-divider>
        </div>
      </v-col>
    </v-row>
    <v-row class="elevation-form pa-3 mb-5">
      <v-col cols="12"><h3>Cloudflare nameservers</h3></v-col>
      <v-col cols="12"
        ><div>
          To use Cloudflare, ensure your authoritative DNS servers, or nameservers have been changed. These are your
          assigned Cloudflare nameservers.
        </div></v-col
      >
      <v-col cols="12">
        <v-row class="bg">
          <v-col cols="2">Type</v-col>
          <v-col cols="3">Value</v-col>
        </v-row>
        <v-row>
          <v-col cols="2">NS</v-col>
          <v-col cols="3">ada.ns.cloudflare.com</v-col>
        </v-row>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import utilities from '@/helpers/utilities.js';
import resourceListMixin from '@/mixins/resource-list';
import { storeService } from '@/apis/store.s';
export default {
  mixins: [resourceListMixin],
  data() {
    return {
      resourceType: 'dns',
      DNS: true,
      utilities,
      editStatus: false,
      idEdit: '',
      itemsSelect: [
        {
          title: 'CNAME',
          value: 'CNAME',
        },
      ],
      TTLSelect: [
        {
          title: 'Auto',
          value: 'Auto',
        },
        {
          title: '2 min',
          value: '2min',
        },
        {
          title: '5 min',
          value: '5min',
        },
        {
          title: '10 min',
          value: '10min',
        },
        {
          title: '15 min',
          value: '15min',
        },
        {
          title: '30 min',
          value: '30min',
        },
        {
          title: '1 hr',
          value: '1hr',
        },
        {
          title: '2 hr',
          value: '1hr',
        },
        {
          title: '5 hr',
          value: '1hr',
        },
        {
          title: '12 hr',
          value: '1hr',
        },
        {
          title: '1 day',
          value: '1day',
        },
      ],
      isShowAddRecords: false,
      inputData: {
        type: 'CNAME',
        name: '',
        address: '',
        TTL: 'Auto',
        proxy: true,
      },
      listData: [],
    };
  },
  methods: {
    clearCache() {
      let res = storeService.clearCache();
      console.log(res);
    },
    // searchName() {
    //   let dataQuery = this.searchInput;
    //   this.$router.replace({ query: dataQuery });
    // },
    onDNS() {
      this.inputData.proxy = true;
    },
    offDNS() {
      this.inputData.proxy = false;
    },
    Add() {
      this.isShowAddRecords = true;
    },
    Cancel() {
      this.isShowAddRecords = false;
      this.inputData = {
        type: 'CNAME',
        name: '',
        address: '',
        TTL: '',
        proxy: true,
      };
    },
    Save() {
      this.isShowAddRecords = false;
      if (this.editStatus) {
        this.listData.splice(this.idEdit, 1, this.inputData);
      } else {
        this.listData.push(this.inputData);
        this.inputData = {
          type: 'CNAME',
          name: '',
          address: '',
          TTL: '',
          proxy: true,
        };
      }
    },
    Edit(ind) {
      this.isShowAddRecords = true;
      this.idEdit = ind;
      this.inputData = Object.assign({}, this.listData[ind]);
    },
  },
};
</script>

<style lang="scss">
.dns {
  .bg {
    background-color: #e6e6e6;
  }
  .v-select__selections input {
    display: none !important;
  }
}
</style>
